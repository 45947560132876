<template>
  <div class="fileVerify">
    <div class="uploadFile" v-show="!result">
      <div class="hint">请上传已签名的PDF文档或者OFD文档，我们会校验文档中的数字证书信息、时间戳信息、文档内容是否被篡改。</div>
      <div class="upload" v-show="!file||!file.name">
        <el-upload ref="elUpload" :show-file-list="false" :http-request="selectFile" drag action="" accept=".pdf,.ofd">
          <img class="icon" src="@/assets/icons/service/uoload-icon.png" alt="">
          <el-button type="primary" size="small">上传文档</el-button>
          <div class="text">暂支持20M以内文档上传；支持符合GB38540和 ISO32000规范PDF验证；支持GB38540和GM0031规范OFD验证 </div>
        </el-upload>
      </div>
      <template v-if="file&&file.name">
        <div class="file">
          <img src="@/assets/icons/service/filesUpload.png" class="left-icon" alt="" srcset="">
          <div class="title">
            <img class="icon" src="@/assets/icons/service/pdf-icon.png" alt="" srcset="">
            <div class="name">{{file.name}}</div>
            <el-button @click="uploadAgain" type="text">重新上传 </el-button>
          </div>
        </div>
        <el-button v-show="file&&file.name" class="verifyButn" @click="pdfVerify" type="primary">立即验证 </el-button>
      </template>
    </div>
    <div class="result" v-if="result">
      <div class="top">
        <!-- <img class="icon" src="@/assets/icons/service/file-verify-icon.png" alt=""> -->
        <div class="text">
          <div class="tt">文档验证完成</div>
          <div v-if="result.allVerifySign===-1">查验结果： <span class="err">未发现签名信息，请上传带有签名信息的文档</span></div>
          <template v-else>
            <template v-if="result.allVerifySign">
              <div>查验结果：<span class="valid">通过</span></div>
              <div>自应用签名以来，签名文档<b>未被篡改 </b>。</div>
            </template>
            <template v-else>
              <div>查验结果：<span class="err">不通过</span></div>
              <div class="err">自签名应用以来，签名文档可能被篡改。</div>
            </template>

            <div v-if="result.unYHCAIssuerNum>0">文档签名所使用的数字证书有<b>{{result.unYHCAIssuerNum}}张非豸信CA颁发</b>。</div>
            <div v-else>文档签名所使用的数字证书均为<b>豸信CA颁发</b>。</div>
          </template>
        </div>
      </div>
      <template v-if="result.allVerifySign!==-1">
        <div class="cert" v-for="(cert,index) in result.resultList" :key="index">
          <div class="info">
            <div class="tt">签名信息<span v-if="cert.signType=='qfz'" class="qfz">骑缝章</span> </div>
            <div><span>签名算法：</span>{{cert.signAlgorithm}}</div>
            <div><span>签署时间：</span>{{cert.signDate}}</div>
            <div><span>签署时间来源:</span>{{cert.hasTimeStamp?'可信时间戳':'本地时间'}}</div>
          </div>
          <div class="res">
            <div class="if">
              <div v-if="cert.verifySign"><i class="valid el-icon-circle-check"></i>签名有效</div>
              <div v-else class="err"><i class="el-icon-circle-close"></i>签名无效（签名信息可能被篡改）</div>
              <div v-if="cert.verifyIssuer"><i class=" valid el-icon-circle-check"></i>豸信CA颁发</div>
              <div v-else class="war"> <i class="el-icon-warning-outline"></i>非豸信CA颁发</div>
            </div>
            <div class="seal">
              <img :src="'data:image/png;base64,'+cert.sealImgBase64" alt="">
            </div>

          </div>
          <div class="info newMargin">
            <div class="tt">证书信息</div>
            <div><span>序列号：</span>{{cert.certSn}}</div>
            <div><span>颁发机构：</span>{{cert.issuerCN}}</div>
            <div><span>有效期：</span>{{cert.certStartDate}}至{{cert.certEndDate}}</div>
            <div><span>持有人：</span><p>{{cert.certCN}}</p></div>
          </div>
          <div class="info newMargin" v-if="cert.hasTimeStamp">
            <div class="tt">时间戳信息</div>
            <div><span>是否有时间戳：</span>{{cert.hasTimeStamp?'是':'否'}}</div>
            <div v-if="cert.hasTimeStamp"><span>时间戳是否有效：</span><span v-if="cert.verifyTimeStamp">有效</span><u v-else>无效</u></div>
            <div v-else><span>时间戳是否有效：</span>--</div>
          </div>
        </div>
      </template>
      <el-button v-else class="again-butn" @click="result = null;file={}" type="primary">重新上传 </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      file: {},
      result: null
    }
  },
  created () { },
  methods: {
    selectFile ({ file }) {
      this.isLoad = true
      const isPDF = (file.type === 'application/pdf')
      const fileTypeTransfer = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isOFD = ['ofd'].includes(fileTypeTransfer.toLowerCase())
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isPDF && !isOFD) {
        this.$message.error('文档格式错误，请上传正确格式文档！')
        return false
      }
      if (!isLt20M) {
        const h = this.$createElement
        this.$alert(
          h('p', undefined, [
            h('span', undefined, '您上传的文件过大，请下载'),
            h(
              'a',
              {
                on: {
                  click: () => {
                    this.$util.downloadById(this.CONFIG.CLIEN_SIDE_ID)
                  }
                }
              },
              '豸信CA签章客户端'
            ),
            h('span', undefined, '进行验证')
          ]),
          '提示',
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '好的',
            type: 'warning'
          }
        )
        return false
      }
      this.file = file
    },
    uploadAgain () {
      this.result = null
      this.$refs.elUpload.$children[0].handleClick()
    },
    pdfVerify () {
      let formData = new FormData()
      this.$tencentCaptcha(val => {
        this.$parent.isLoading = true
        formData.append('pdfFile', this.file)
        formData.append('randStr', val.randStr)
        formData.append('ticket', val.ticket)
        this.$api.pdfVerify(formData).then(res => {
          this.$parent.isLoading = false
          if (res.code === '0') {
            this.result = res.data
          } else if (res.code === '-1') {
            this.result = { allVerifySign: -1 }
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.fileVerify {
  min-height: calc(100vh - 108px);
  background: #ffffff;
  // border: 1px solid #dee2e8;
  padding: 24px 32px 48px;
  margin-top: 14px;
  .uploadFile {
    padding-top: 34px;
    .hint {
      font-size: 16px;
      color: #333333;
      line-height: 21px;
      letter-spacing: 1px;
      text-align: center;
      margin-bottom: 40px;
    }
    .upload {
      ::v-deep.el-upload {
        width: 100%;
        .el-upload-dragger {
          height: 209px;
          background: #fbfcff;
          border: 1px dashed #d5dbe2;
          width: 100%;
          padding-top: 32px;
          border-radius: 0;
          .icon {
            width: 69px;
            height: 68px;
            display: block;
            margin: 0 auto;
            margin-bottom: 9px;
          }
          .el-button {
            margin-bottom: 24px;
            padding: 7px 15px;
            width: 92px;
          }
          .text {
            font-size: 14px;
            color: #7789a4;
            line-height: 19px;
            letter-spacing: 1px;
          }
        }
      }
    }
    .file {
      height: 86px;
      width: 848px;
      background: #f7faff;
      border-radius: 2px;
      display: flex;
      margin: 0 auto;
      margin-bottom: 140px;
      padding-right: 24px;
      .left-icon {
        width: 24px;
        height: 86px;
        margin-right: 32px;
      }
      .title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 86px;
        .icon {
          width: 24px;
          height: 25px;
          margin-right: 12px;
          flex-shrink: 0;
        }
        .name {
          width: 100%;
          .err {
            color: #ed5448;
            margin-left: 12px;
          }
        }
        .el-button {
          flex-shrink: 0;
        }
      }
    }
    .verifyButn {
      width: 182px;
      margin: 0 auto;
      display: block;
    }
  }
  .result {
    .valid {
      color: #00b800 !important;
    }
    .err {
      color: #ff443e !important;
    }
    .war {
      color: #eea209 !important;
    }
    .top {
      height: 253px;
      padding: 0px;
      display: flex;
      // margin-bottom: 64px;
      background: url('../../../assets/icons/service/file-verify-icon.png') no-repeat right top;
      background-size: 443px 445px;
      .icon {
        width: 243px;
        height: 244px;
        margin-right: 114px;
      }
      .text {
        font-size: 14px;
        color: #333333;
        line-height: 19px;
        letter-spacing: 1px;
        padding-top: 23px;
        .tt {
          font-size: 24px;
          font-weight: bold;
          color: #303b50;
          line-height: 32px;
          margin-bottom: 40px;
        }
        & > div {
          margin-bottom: 24px;
        }
      }
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #303b50;
      line-height: 20px;
      position: relative;
      padding-left: 12px;
      margin-bottom: 24px;
      &::before {
        position: absolute;
        width: 3px;
        height: 16px;
        content: '';
        background: $primaryColor;
        top: 2px;
        left: 0;
      }
    }
    .cert {
      border: 1px solid #e6e8ea;
      padding: 32px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
      &:last-child {
        margin: 0;
      }
      & > div {
        width: 50%;
        min-height: 148px;
      }
      .info {
        font-size: 14px;
        color: #333333;
        line-height: 19px;
        letter-spacing: 1px;
        & > div {
          margin-bottom: 20px;
          display: flex;
          &:last-child {
            margin: 0;
          }
          span {
            width: 120px;
          }
          p{
            flex: 1;
          }
          u {
            text-decoration: none;
            color: #e89e1c;
          }
        }
        .tt {
          color: #aeb5be;
          display: flex;
          align-items: center;
          .qfz {
            line-height: 24px;
            border: 1px #90b7ff solid;
            background-color: #deeaff;
            padding: 0 10px;
            color: $primaryColor;
            width: auto;
            margin-left: 24px;
            border-radius: 4px;
          }
        }
      }
      .res {
        display: flex;
        justify-content: space-between;
        padding-right: 28px;
        padding-top: 8px;
        .if {
          padding-top: 8px;
          font-size: 14px;
          line-height: 19px;
          & > div {
            margin-bottom: 24px;
            color: #5c6269;
            &.err {
              color: #ff443e;
            }
            &.war {
              color: #eea209;
            }
          }
          i {
            margin-right: 4px;
          }
        }
        .seal {
          max-width: 112px;
          max-height: 112px;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
    .again-butn {
      width: 182px;
      margin: 64px auto;
      display: block;
    }
  }
}
.newMargin {
  margin-top: 12px;
}
</style>